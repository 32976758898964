:root {
  --black: rgba(0, 0, 0, 1);
  --blue: rgba(19, 100, 255, 1);
  --button-font-family: "Epilogue-SemiBold", Helvetica;
  --button-font-size: 20px;
  --button-font-style: normal;
  --button-font-weight: 600;
  --button-letter-spacing: -1.2px;
  --button-line-height: 89.99999761581421%;
  --green: rgba(19, 242, 135, 1);
  --grey: rgba(147, 147, 147, 1);
  --h-1-font-family: "Epilogue-Bold", Helvetica;
  --h-1-font-size: 40px;
  --h-1-font-style: normal;
  --h-1-font-weight: 700;
  --h-1-letter-spacing: -0.8px;
  --h-1-line-height: 129.99999523162842%;
  --h-2-font-family: "Epilogue-Bold", Helvetica;
  --h-2-font-size: 24px;
  --h-2-font-style: normal;
  --h-2-font-weight: 700;
  --h-2-letter-spacing: -0.48px;
  --h-2-line-height: 129.99999523162842%;
  --p-1-font-family: "Epilogue-Regular", Helvetica;
  --p-1-font-size: 22px;
  --p-1-font-style: normal;
  --p-1-font-weight: 400;
  --p-1-letter-spacing: -0.44px;
  --p-1-line-height: 139.9999976158142%;
  --p-2-font-family: "Epilogue-Regular", Helvetica;
  --p-2-font-size: 16px;
  --p-2-font-style: normal;
  --p-2-font-weight: 400;
  --p-2-letter-spacing: -0.32px;
  --p-2-line-height: 120.00000476837158%;
  --white: rgba(255, 255, 255, 1);
  --yellow: rgba(239, 227, 71, 1);
}
