:root {
  --black: #000;
  --blue: #1364ff;
  --button-font-family: "Epilogue-SemiBold", Helvetica;
  --button-font-size: 20px;
  --button-font-style: normal;
  --button-font-weight: 600;
  --button-letter-spacing: -1.2px;
  --button-line-height: 90.0%;
  --green: #13f287;
  --grey: #939393;
  --h-1-font-family: "Epilogue-Bold", Helvetica;
  --h-1-font-size: 40px;
  --h-1-font-style: normal;
  --h-1-font-weight: 700;
  --h-1-letter-spacing: -.8px;
  --h-1-line-height: 130.0%;
  --h-2-font-family: "Epilogue-Bold", Helvetica;
  --h-2-font-size: 24px;
  --h-2-font-style: normal;
  --h-2-font-weight: 700;
  --h-2-letter-spacing: -.48px;
  --h-2-line-height: 130.0%;
  --p-1-font-family: "Epilogue-Regular", Helvetica;
  --p-1-font-size: 22px;
  --p-1-font-style: normal;
  --p-1-font-weight: 400;
  --p-1-letter-spacing: -.44px;
  --p-1-line-height: 140.0%;
  --p-2-font-family: "Epilogue-Regular", Helvetica;
  --p-2-font-size: 16px;
  --p-2-font-style: normal;
  --p-2-font-weight: 400;
  --p-2-letter-spacing: -.32px;
  --p-2-line-height: 120%;
  --white: #fff;
  --yellow: #efe347;
}

.index {
  background-color: #000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.index .HTBS-page {
  background-color: var(--black);
}

.index .overlap {
  width: 360px;
  height: 1737px;
  position: absolute;
  top: -235px;
  left: 0;
}

.index .blue-blur {
  background-color: var(--blue);
  filter: blur(200px);
  opacity: .24;
  border-radius: 180px;
  width: 360px;
  height: 360px;
  position: absolute;
  top: 1046px;
  left: 0;
}

.index .div {
  background-color: var(--blue);
  filter: blur(200px);
  opacity: .24;
  border-radius: 180px;
  width: 360px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .stroke-circle {
  width: 360px;
  height: 360px;
  position: absolute;
  top: 415px;
  left: 0;
}

.index .stroke-circle-2 {
  border: 1px solid;
  border-color: var(--white);
  opacity: .16;
  border-radius: 180px;
  width: 360px;
  height: 360px;
  position: absolute;
  top: 55px;
  left: 0;
}

.index .txt-container {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 312px;
  display: flex;
  position: absolute;
  top: 371px;
  left: 24px;
}

.index .vector {
  width: 223px;
  height: 52px;
  position: absolute;
  top: 40px;
  left: 43px;
}

.index .title {
  color: #0000;
  letter-spacing: -.8px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  position: relative;
}

.index .text-wrapper {
  color: #fff;
}

.index .span {
  color: #13f287;
  margin-left: -50px;
}

.index .span-2 {
  color: #13f287;
  margin-left: 0;
}

.index .form-container {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .input {
  color: var(--grey);
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  background-color: #ffffff29;
  border: 1px solid #ffffff3d;
  border-radius: 80px;
  flex: 1;
  align-self: stretch;
  width: 100%;
  height: 50px;
  margin-top: -1px;
  padding: 16px 24px;
  position: relative;
}

.index .button {
  all: unset;
  background-color: var(--blue);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 60px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 16px 40px;
  display: flex;
  position: relative;
}

@keyframes buttonPressed {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

.index .button.clicked, .index .button-3.clicked {
  background-color: #4d8cff;
  transition: background-color .15s ease-out;
  transform: scale(.95);
}

.index .text-wrapper-2 {
  color: var(--white);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.index .header {
  justify-content: space-between;
  align-items: center;
  width: 312px;
  display: flex;
  position: absolute;
  top: 267px;
  left: 24px;
}

.index .button-4 {
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  white-space: nowrap;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  display: inline-flex;
  position: relative;
}

.index .button-4.clicked {
  transition: transform .15s ease-in-out;
  transform: scale(.95);
}

.index .button-4:hover {
  animation: .1s ease-out buttonPressed;
}

.index .logo {
  width: 100px !important;
  height: 22px !important;
  position: relative !important;
}

.index .button-2 {
  background-color: var(--blue);
  cursor: pointer;
  border-radius: 60px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  display: inline-flex;
  position: relative;
}

.index .button-2.clicked {
  background-color: #4d8cff;
  transition: background-color .15s ease-out;
  transform: scale(.95);
}

.index .rectangle {
  background-color: var(--white);
  border-radius: 2px;
  width: 6px;
  height: 6px;
  position: relative;
}

.index .img {
  width: 41px;
  height: 66px;
  position: absolute;
  top: 460px;
  left: 19px;
}

.index .cards {
  -webkit-backdrop-filter: blur(24px) brightness();
  backdrop-filter: blur(24px) brightness();
  background-color: #93939329;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  display: flex;
  position: absolute;
  top: 715px;
  left: 24px;
}

.index .card {
  border-radius: 24px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 40px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.index .ant-design-smile {
  width: 160px;
  height: 160px;
  position: relative;
}

.index .overlap-group {
  width: 74px;
  height: 131px;
  position: relative;
  top: 16px;
  left: 20px;
}

.index .vector-2 {
  width: 52px;
  height: 38px;
  position: absolute;
  top: 45px;
  left: 0;
}

.index .vector-3 {
  width: 52px;
  height: 44px;
  position: absolute;
  top: 86px;
  left: 1px;
}

.index .vector-4 {
  width: 43px;
  height: 44px;
  position: absolute;
  top: 86px;
  left: 30px;
}

.index .vector-5 {
  width: 19px;
  height: 28px;
  position: absolute;
  top: 28px;
  left: 53px;
}

.index .vector-6 {
  width: 19px;
  height: 2px;
  position: absolute;
  top: 19px;
  left: 48px;
}

.index .ellipse {
  border: 2px solid;
  border-color: var(--white);
  border-radius: 18.12px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 34px;
}

.index .vector-7 {
  width: 47px;
  height: 104px;
  position: absolute;
  top: 27px;
  left: 27px;
}

.index .copy-container {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .title-2 {
  color: var(--white);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  width: 418px;
  margin-top: -1px;
  margin-left: -77px;
  margin-right: -77px;
  position: relative;
}

.index .p {
  color: var(--grey);
  letter-spacing: -.32px;
  text-align: center;
  align-self: stretch;
  font-family: Epilogue, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.index .group {
  width: 56px;
  height: 48px;
  position: absolute;
  top: 24px;
  left: 180px;
}

.index .copy-container-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 40px 24px;
  display: flex;
  position: relative;
}

.index .copy-container-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.index .title-3 {
  color: var(--green);
  letter-spacing: -.64px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  position: relative;
}

.index .title-33 {
  color: var(--white);
  letter-spacing: -.64px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  position: relative;
}

.index .frame {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.index .frame-2 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.index .vector-stroke {
  width: 14px;
  height: 10px;
  position: relative;
}

.index .title-4 {
  color: var(--white);
  letter-spacing: -.32px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.index .group-2 {
  width: 43px;
  height: 80px;
  position: absolute;
  top: 589px;
  left: 135px;
}

.index .ant-design-smile-2 {
  width: 160px;
  height: 160px;
  position: relative;
  transform: rotate(180deg);
}

.index .overlap-group-2 {
  width: 121px;
  height: 131px;
  position: absolute;
  top: 12px;
  left: 22px;
}

.index .vector-8 {
  width: 49px;
  height: 34px;
  position: absolute;
  top: 86px;
  left: 1px;
  transform: rotate(-180deg);
}

.index .vector-9 {
  width: 52px;
  height: 44px;
  position: absolute;
  top: 1px;
  left: 50px;
  transform: rotate(-180deg);
}

.index .vector-10 {
  width: 43px;
  height: 44px;
  position: absolute;
  top: 1px;
  left: 28px;
  transform: rotate(-180deg);
}

.index .vector-11 {
  width: 70px;
  height: 43px;
  position: absolute;
  top: 87px;
  left: 50px;
  transform: rotate(-180deg);
}

.index .vector-12 {
  width: 17px;
  height: 6px;
  position: absolute;
  top: 108px;
  left: 47px;
  transform: rotate(-180deg);
}

.index .ellipse-2 {
  border: 2px solid;
  border-color: var(--white);
  border-radius: 18.12px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 95px;
  left: 32px;
}

.index .vector-13 {
  width: 99px;
  height: 131px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.index .title-5 {
  width: 15px;
  height: 12px;
  position: absolute;
  top: 143px;
  left: 5px;
  transform: rotate(-180deg);
}

.index .title-6 {
  color: var(--yellow);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  width: 418px;
  margin-top: -1px;
  margin-left: -77px;
  margin-right: -77px;
  position: relative;
}

.index .group-3 {
  width: 21px;
  height: 80px;
  position: absolute;
  top: 331px;
  left: 146px;
}

.index .overlap-2 {
  width: 315px;
  height: 157px;
  position: absolute;
  top: 1541px;
  left: 24px;
}

.index .txt-container-2 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 312px;
  display: flex;
  position: absolute;
  top: 41px;
  left: 0;
}

.index .title-7 {
  color: #0000;
  letter-spacing: -.64px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  position: relative;
}

.index .text-wrapper-3 {
  color: #1364ff;
}

.index .vector-14 {
  width: 77px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 238px;
}

.index .graphics {
  width: 360px;
  height: 298px;
  position: absolute;
  top: 1738px;
  left: 0;
  overflow: hidden;
}

.index .overlap-3 {
  width: 766px;
  height: 935px;
  position: relative;
  top: 5px;
  left: -201px;
}

.index .overlap-4 {
  width: 766px;
  height: 935px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .blue-blur-2 {
  background-color: var(--blue);
  filter: blur(263.33px);
  opacity: .24;
  border-radius: 359.45px;
  width: 719px;
  height: 719px;
  position: absolute;
  top: 217px;
  left: 21px;
}

.index .image {
  width: 186px;
  height: 293px;
  position: absolute;
  top: 0;
  left: 288px;
}

.index .rectangle-2 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 186px;
  height: 175px;
  position: absolute;
  top: 117px;
  left: 288px;
}

.index .image-2 {
  width: 67px;
  height: 257px;
  position: absolute;
  top: 36px;
  left: 493px;
}

.index .image-3 {
  object-fit: cover;
  width: 68px;
  height: 257px;
  position: absolute;
  top: 36px;
  left: 201px;
}

.index .rectangle-3 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 148px;
  height: 90px;
  position: absolute;
  top: 202px;
  left: 491px;
}

.index .rectangle-4 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 148px;
  height: 90px;
  position: absolute;
  top: 202px;
  left: 119px;
}

.index .rectangle-5 {
  background-color: #000;
  border-radius: 28.97px;
  width: 61px;
  height: 16px;
  position: absolute;
  top: 11px;
  left: 350px;
}

.index .rectangle-6 {
  background-color: #000;
  border-radius: 28.97px;
  width: 43px;
  height: 11px;
  position: absolute;
  top: 47px;
  left: 172px;
}

.index .rectangle-7 {
  background-color: #000;
  border-radius: 28.97px;
  width: 43px;
  height: 11px;
  position: absolute;
  top: 47px;
  left: 545px;
}

.index .card-2 {
  opacity: .3;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  display: flex;
  position: absolute;
  top: 193px;
  left: 666px;
}

.index .date {
  -webkit-backdrop-filter: blur(24.48px) brightness();
  backdrop-filter: blur(24.48px) brightness();
  background-color: #1364ffe0;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: .31px;
  border-radius: 4.9px 4.9px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12.24px;
  width: 100%;
  padding: 4.9px 7.34px;
  display: flex;
  position: relative;
}

.index .text-wrapper-4 {
  color: var(--white);
  letter-spacing: -.1px;
  text-align: center;
  align-self: stretch;
  margin-top: -.31px;
  font-family: Epilogue, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.9px;
  position: relative;
}

.index .text-container {
  -webkit-backdrop-filter: blur(24.48px) brightness();
  backdrop-filter: blur(24.48px) brightness();
  background-color: #29292b;
  border-radius: 0 0 4.9px 4.9px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.34px;
  width: 100%;
  padding: 7.34px 4.9px;
  display: flex;
  position: relative;
}

.index .copy-container-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.9px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .content-row {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.45px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .text-wrapper-5 {
  color: var(--white);
  letter-spacing: -.1px;
  width: 6.12px;
  margin-top: -.31px;
  font-family: Epilogue, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.9px;
  position: relative;
}

.index .text-wrapper-6 {
  color: var(--white);
  letter-spacing: -.1px;
  flex: 1;
  margin-top: -.31px;
  font-family: Epilogue, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.9px;
  position: relative;
}

.index .tag {
  flex: none;
  margin-top: -1958.92px;
  margin-left: -1984.58px;
  position: relative;
}

.index .tag-2 {
  flex: none;
  margin-top: -1969.82px;
  margin-left: -1984.58px;
  position: relative;
}

.index .tag-3 {
  flex: none;
  margin-top: -1937.68px;
  margin-left: -2069.95px;
  position: relative;
}

.index .text-wrapper-7 {
  color: var(--green);
  letter-spacing: -.37px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Epilogue, Helvetica;
  font-size: 6.1px;
  font-weight: 600;
  line-height: 5.5px;
  position: relative;
}

.index .frame-3 {
  opacity: .4;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.22px;
  width: 99px;
  display: flex;
  position: absolute;
  top: 165px;
  left: 0;
}

.index .column {
  -webkit-backdrop-filter: blur(4.86px) brightness();
  backdrop-filter: blur(4.86px) brightness();
  background-color: #ffffff29;
  border-radius: 3.65px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.43px;
  width: 100%;
  padding: 4.86px;
  display: flex;
  position: relative;
}

.index .text-wrapper-8 {
  color: var(--white);
  letter-spacing: -.07px;
  align-self: stretch;
  margin-top: -.3px;
  font-family: Epilogue, Helvetica;
  font-size: 3.6px;
  font-weight: 400;
  line-height: 4.4px;
  position: relative;
}

.index .text-wrapper-9 {
  color: var(--white);
  letter-spacing: -.1px;
  align-self: stretch;
  font-family: Epilogue, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.8px;
  position: relative;
}

.index .tag-4 {
  width: 5px;
  height: 5px;
  position: absolute;
  top: -1904px;
  left: -1310px;
}

.index .column-2 {
  -webkit-backdrop-filter: blur(4.86px) brightness();
  backdrop-filter: blur(4.86px) brightness();
  background-color: #ffffff29;
  border-radius: 3.65px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.43px;
  width: 100%;
  padding: 4.86px;
  display: flex;
  position: relative;
}

.index .tag-5 {
  width: 5px;
  height: 5px;
  position: absolute;
  top: -1927px;
  left: -1310px;
}

.index .tag-6 {
  width: 5px;
  height: 5px;
  position: absolute;
  top: -1956px;
  left: -1310px;
}

.index .tag-7 {
  width: 5px;
  height: 5px;
  position: absolute;
  top: -1980px;
  left: -1310px;
}

.index .text-container-2 {
  -webkit-backdrop-filter: blur(24.44px) brightness();
  backdrop-filter: blur(24.44px) brightness();
  opacity: .4;
  background-color: #29292b;
  border-radius: 4.89px;
  flex-direction: column;
  align-items: center;
  gap: 7.33px;
  width: 99.92px;
  padding: 7.33px 4.89px;
  display: flex;
  position: absolute;
  top: 75px;
  left: 666px;
}

.index .text-wrapper-10 {
  color: var(--white);
  letter-spacing: -.15px;
  align-self: stretch;
  margin-top: -.31px;
  font-family: Epilogue, Helvetica;
  font-size: 7.3px;
  font-weight: 700;
  line-height: 9.5px;
  position: relative;
}

.index .copy-container-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.89px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .content-row-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.67px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .solar-book-outline {
  width: 7.33px;
  height: 7.33px;
  margin-top: -1843.25px;
  margin-left: -2069.95px;
  position: relative;
}

.index .text-wrapper-11 {
  color: var(--white);
  letter-spacing: -.1px;
  flex: 1;
  font-family: Epilogue, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.9px;
  position: relative;
}

.index .back {
  width: 4.89px;
  height: 4.89px;
  margin-top: -1842.03px;
  margin-left: -1979.81px;
  position: relative;
}

.index .vector-15 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -1831.03px;
  margin-left: -2069.95px;
  position: relative;
}

.index .fluent-list {
  width: 7.33px;
  height: 7.33px;
  margin-top: -1860.36px;
  margin-left: -2069.95px;
  position: relative;
}

.index .back-2 {
  width: 4.89px;
  height: 4.89px;
  margin-top: -1859.14px;
  margin-left: -1979.81px;
  position: relative;
}

.index .vector-16 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -1813.92px;
  margin-left: -2069.95px;
  position: relative;
}

.index .basil-calendar {
  width: 7.33px;
  height: 7.33px;
  margin-top: -1877.47px;
  margin-left: -2069.95px;
  position: relative;
}

.index .back-3 {
  width: 4.89px;
  height: 4.89px;
  margin-top: -1876.25px;
  margin-left: -1979.81px;
  position: relative;
}

.index .vector-17 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -1796.81px;
  margin-left: -2069.95px;
  position: relative;
}

.index .solar-archive {
  width: 7.33px;
  height: 7.33px;
  margin-top: -1894.58px;
  margin-left: -2069.95px;
  position: relative;
}

.index .back-4 {
  width: 4.89px;
  height: 4.89px;
  margin-top: -1893.36px;
  margin-left: -1979.81px;
  position: relative;
}

.index .vector-18 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -1779.69px;
  margin-left: -2069.95px;
  position: relative;
}

.index .solar-document {
  width: 7.33px;
  height: 7.33px;
  margin-top: -1911.7px;
  margin-left: -2069.95px;
  position: relative;
}

.index .back-5 {
  width: 4.89px;
  height: 4.89px;
  margin-top: -1910.47px;
  margin-left: -1979.81px;
  position: relative;
}

.index .txt-container-3 {
  background-color: var(--blue);
  border-radius: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 312px;
  padding: 80px 16px;
  display: flex;
  position: absolute;
  top: 2059px;
  left: 24px;
  overflow: hidden;
}

.index .vector-19 {
  width: 119px;
  height: 51px;
  position: absolute;
  top: 105px;
  left: 132px;
}

.index .title-8 {
  color: var(--white);
  letter-spacing: -.64px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  position: relative;
}

.index .cards-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .frame-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.index .overlap-group-wrapper {
  width: 122px;
  height: 120px;
  margin-right: -2px;
  position: relative;
}

.index .overlap-group-3 {
  background-color: var(--black);
  border-radius: 60px;
  width: 120px;
  height: 120px;
  position: relative;
}

.index .title-9 {
  color: var(--white);
  letter-spacing: -.44px;
  width: 96px;
  font-family: Epilogue, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 24.2px;
  position: absolute;
  top: 45px;
  left: 12px;
}

.index .game-icons-female {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .rectangle-8 {
  background-color: var(--blue);
  width: 12px;
  height: 126px;
  position: absolute;
  top: -3px;
  left: 54px;
  transform: rotate(45deg);
}

.index .title-10 {
  color: var(--white);
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  text-align: center;
  width: 120px;
  position: relative;
}

.index .div-wrapper {
  width: 126px;
  height: 120px;
  margin-right: -6px;
  position: relative;
}

.index .overlap-5 {
  width: 106px;
  height: 97px;
  position: absolute;
  top: -3px;
  left: 45px;
}

.index .overlap-6 {
  width: 106px;
  height: 97px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .title-11 {
  color: var(--yellow);
  letter-spacing: 2.24px;
  text-align: center;
  white-space: nowrap;
  height: 31px;
  font-family: Epilogue, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 30.8px;
  position: absolute;
  top: 48px;
  left: 8px;
}

.index .rectangle-9 {
  border: 1px solid;
  border-color: var(--yellow);
  width: 30px;
  height: 37px;
  position: absolute;
  top: 42px;
  left: 0;
}

.index .rectangle-10 {
  background-color: var(--blue);
  width: 12px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 9px;
  transform: rotate(45deg);
}

.index .overlap-7 {
  width: 30px;
  height: 37px;
  position: absolute;
  top: 42px;
  left: 32px;
}

.index .title-12 {
  color: var(--yellow);
  letter-spacing: 2.24px;
  text-align: center;
  white-space: nowrap;
  height: 31px;
  font-family: Epilogue, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 30.8px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.index .rectangle-11 {
  border: 1px solid;
  border-color: var(--yellow);
  width: 30px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .overlap-8 {
  width: 30px;
  height: 37px;
  position: absolute;
  top: 39px;
  left: 13px;
}

.index .title-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.index .title-13 {
  color: var(--white);
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  text-align: center;
  width: 120px;
  margin-top: -1px;
  position: relative;
}

.index .title-14 {
  color: var(--green);
  letter-spacing: -.44px;
  text-align: center;
  font-family: Epilogue, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 24.2px;
  position: absolute;
  top: 36px;
  left: 16px;
}

.index .frame-5 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 312px;
  display: flex;
  position: absolute;
  top: 2773px;
  left: 24px;
}

.index .txt-container-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .copy-container-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .content-row-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .text-wrapper-12 {
  color: var(--white);
  letter-spacing: -.32px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.index .tag-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 0 0 0 24px;
  display: inline-flex;
  position: relative;
}

.index .tag-8 {
  background-color: var(--green);
  cursor: pointer;
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: relative;
}

.index .tag-8:hover {
  background-color: #4caf50;
}

.index .text-wrapper-13 {
  color: var(--black);
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.index .check {
  width: 16px;
  height: 16px;
  position: relative;
}

.index .vector-20 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-left: -.5px;
  margin-right: -.5px;
  position: relative;
}

.index .tag-9 {
  background-color: var(--yellow);
  cursor: pointer;
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: relative;
}

.index .tag-9:hover {
  background-color: #d8d5a5;
}

.index .txt-container-5 {
  background-color: #121212;
  border-radius: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 312px;
  padding: 64px 16px;
  display: flex;
  position: absolute;
  top: 3349px;
  left: 24px;
  overflow: hidden;
}

.index .blue-blur-3 {
  background-color: var(--blue);
  filter: blur(200px);
  opacity: .24;
  border-radius: 156px;
  width: 312px;
  height: 312px;
  position: absolute;
  top: 231px;
  left: 0;
}

.index .frame-6 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.index .title-15 {
  color: var(--white);
  letter-spacing: -.64px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  position: relative;
}

.index .tag-10 {
  background-color: var(--blue);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: relative;
}

.index .tag-100 {
  background-color: var(--blue);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: relative;
  top: -44px;
  left: -33px;
}

.index .text-wrapper-14 {
  color: var(--white);
  letter-spacing: 1.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  position: relative;
}

.index .footer {
  background-color: #0000;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 312px;
  display: flex;
  position: absolute;
  top: 3816px;
  left: 24px;
}

.index .header-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .logo-2 {
  width: 140px !important;
  height: 31px !important;
  position: relative !important;
}

.index .menu-items {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .menu-item {
  border: 1px solid #ffffff14;
  border-radius: 80px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.index .text-wrapper-15 {
  color: #fff;
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.index .vector-21 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.index .text-wrapper-16 {
  color: #fff;
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.index .overlap-9 {
  height: 4800px;
  position: relative;
  top: -1204px;
}

.index .lines {
  width: 1281px;
  height: 3600px;
  position: absolute;
  top: 1204px;
  left: 80px;
}

.index .stroke-circle-3 {
  width: 802px;
  height: 802px;
  position: absolute;
  top: 1440px;
  left: 319px;
}

.index .blue-blur-4 {
  background-color: var(--blue);
  filter: blur(400px);
  opacity: .24;
  border-radius: 262px;
  width: 524px;
  height: 524px;
  position: absolute;
  top: 1797px;
  left: 458px;
}

.index .overlap-wrapper {
  width: 1440px;
  height: 452px;
  position: absolute;
  top: 2579px;
  left: 0;
  overflow: hidden;
}

.index .overlap-10 {
  width: 1163px;
  height: 1421px;
  position: relative;
  top: 8px;
  left: 142px;
}

.index .overlap-group-4 {
  width: 1163px;
  height: 1421px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .blue-blur-5 {
  background-color: var(--blue);
  filter: blur(400px);
  opacity: .24;
  border-radius: 546px;
  width: 1092px;
  height: 1092px;
  position: absolute;
  top: 329px;
  left: 32px;
}

.index .image-4 {
  width: 283px;
  height: 444px;
  position: absolute;
  top: 0;
  left: 437px;
}

.index .rectangle-12 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 282px;
  height: 266px;
  position: absolute;
  top: 178px;
  left: 437px;
}

.index .image-5 {
  width: 225px;
  height: 390px;
  position: absolute;
  top: 54px;
  left: 749px;
}

.index .image-6 {
  object-fit: cover;
  width: 225px;
  height: 390px;
  position: absolute;
  top: 54px;
  left: 183px;
}

.index .rectangle-13 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 225px;
  height: 137px;
  position: absolute;
  top: 307px;
  left: 746px;
}

.index .rectangle-14 {
  background: linear-gradient(#0000 0%, #000 100%);
  width: 225px;
  height: 137px;
  position: absolute;
  top: 307px;
  left: 180px;
}

.index .rectangle-15 {
  background-color: #000;
  border-radius: 44px;
  width: 93px;
  height: 24px;
  position: absolute;
  top: 17px;
  left: 532px;
}

.index .rectangle-16 {
  background-color: #000;
  border-radius: 44px;
  width: 65px;
  height: 16px;
  position: absolute;
  top: 71px;
  left: 262px;
}

.index .rectangle-17 {
  background-color: #000;
  border-radius: 44px;
  width: 65px;
  height: 16px;
  position: absolute;
  top: 71px;
  left: 828px;
}

.index .card-3 {
  opacity: .3;
  flex-direction: column;
  align-items: flex-start;
  width: 152px;
  display: flex;
  position: absolute;
  top: 294px;
  left: 1011px;
}

.index .date-2 {
  -webkit-backdrop-filter: blur(37.19px) brightness();
  backdrop-filter: blur(37.19px) brightness();
  background-color: #1364ffe0;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: .46px;
  border-radius: 7.44px 7.44px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.59px;
  width: 100%;
  padding: 7.44px 11.16px;
  display: flex;
  position: relative;
}

.index .text-wrapper-17 {
  color: var(--white);
  letter-spacing: -.15px;
  text-align: center;
  align-self: stretch;
  margin-top: -.46px;
  font-family: Epilogue, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.index .text-container-3 {
  -webkit-backdrop-filter: blur(37.19px) brightness();
  backdrop-filter: blur(37.19px) brightness();
  background-color: #29292b;
  border-radius: 0 0 7.44px 7.44px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 11.16px;
  width: 100%;
  padding: 11.16px 7.44px;
  display: flex;
  position: relative;
}

.index .copy-container-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.44px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .content-row-4 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .text-wrapper-18 {
  color: var(--white);
  letter-spacing: -.15px;
  width: 9.3px;
  margin-top: -.46px;
  font-family: Epilogue, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.index .text-wrapper-19 {
  color: var(--white);
  letter-spacing: -.15px;
  flex: 1;
  margin-top: -.46px;
  font-family: Epilogue, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.index .tag-11 {
  flex: none;
  position: relative;
}

.index .text-wrapper-20 {
  color: var(--green);
  letter-spacing: -.56px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Epilogue, Helvetica;
  font-size: 9.3px;
  font-weight: 600;
  line-height: 8.4px;
  position: relative;
}

.index .frame-7 {
  opacity: .4;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.85px;
  width: 151px;
  display: flex;
  position: absolute;
  top: 251px;
  left: 0;
}

.index .column-3 {
  -webkit-backdrop-filter: blur(7.39px) brightness();
  backdrop-filter: blur(7.39px) brightness();
  background-color: #ffffff29;
  border-radius: 5.54px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.69px;
  width: 100%;
  padding: 7.39px;
  display: flex;
  position: relative;
}

.index .text-wrapper-21 {
  color: var(--white);
  letter-spacing: -.11px;
  align-self: stretch;
  margin-top: -.46px;
  font-family: Epilogue, Helvetica;
  font-size: 5.5px;
  font-weight: 400;
  line-height: 6.6px;
  position: relative;
}

.index .text-wrapper-22 {
  color: var(--white);
  letter-spacing: -.15px;
  align-self: stretch;
  font-family: Epilogue, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.index .tag-12 {
  width: 7px;
  height: 7px;
  position: absolute;
  top: 7px;
  left: 136px;
}

.index .column-4 {
  -webkit-backdrop-filter: blur(7.39px) brightness();
  backdrop-filter: blur(7.39px) brightness();
  background-color: #ffffff29;
  border-radius: 5.54px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.69px;
  width: 100%;
  padding: 7.39px;
  display: flex;
  position: relative;
}

.index .text-container-4 {
  -webkit-backdrop-filter: blur(37.13px) brightness();
  backdrop-filter: blur(37.13px) brightness();
  opacity: .4;
  background-color: #29292b;
  border-radius: 7.43px;
  flex-direction: column;
  align-items: center;
  gap: 11.14px;
  width: 151.77px;
  padding: 11.14px 7.43px;
  display: flex;
  position: absolute;
  top: 115px;
  left: 1011px;
}

.index .text-wrapper-23 {
  color: var(--white);
  letter-spacing: -.22px;
  align-self: stretch;
  margin-top: -.46px;
  font-family: Epilogue, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 14.5px;
  position: relative;
}

.index .copy-container-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.43px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .content-row-5 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.57px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .img-2 {
  width: 11.14px;
  height: 11.14px;
  position: relative;
}

.index .text-wrapper-24 {
  color: var(--white);
  letter-spacing: -.15px;
  flex: 1;
  font-family: Epilogue, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.index .back-6 {
  width: 7.43px;
  height: 7.43px;
  position: relative;
}

.index .vector-22 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-left: -.23px;
  margin-right: -.23px;
  position: relative;
}

.index .cards-3 {
  -webkit-backdrop-filter: blur(24px) brightness();
  backdrop-filter: blur(24px) brightness();
  background-color: #93939329;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  width: 1280px;
  display: flex;
  position: absolute;
  top: 1730px;
  left: 80px;
}

.index .card-4 {
  border-radius: 24px;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  padding: 64px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.index .ant-design-smile-3 {
  width: 240px;
  height: 240px;
  position: relative;
}

.index .overlap-group-5 {
  width: 111px;
  height: 197px;
  position: relative;
  top: 25px;
  left: 30px;
}

.index .vector-23 {
  width: 79px;
  height: 56px;
  position: absolute;
  top: 67px;
  left: 0;
}

.index .vector-24 {
  width: 77px;
  height: 65px;
  position: absolute;
  top: 130px;
  left: 2px;
}

.index .vector-25 {
  width: 64px;
  height: 65px;
  position: absolute;
  top: 130px;
  left: 45px;
}

.index .vector-26 {
  width: 28px;
  height: 43px;
  position: absolute;
  top: 41px;
  left: 79px;
}

.index .vector-27 {
  width: 28px;
  height: 3px;
  position: absolute;
  top: 28px;
  left: 72px;
}

.index .ellipse-3 {
  border: 3px solid;
  border-color: var(--white);
  border-radius: 27.19px;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 52px;
}

.index .vector-28 {
  width: 70px;
  height: 156px;
  position: absolute;
  top: 41px;
  left: 41px;
}

.index .title-16 {
  color: var(--white);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  width: 418px;
  margin-top: -1px;
  margin-left: -19.67px;
  margin-right: -19.67px;
  position: relative;
}

.index .title-17 {
  color: var(--grey);
  font-family: var(--p-1-font-family);
  font-size: var(--p-1-font-size);
  font-style: var(--p-1-font-style);
  font-weight: var(--p-1-font-weight);
  letter-spacing: var(--p-1-letter-spacing);
  line-height: var(--p-1-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.index .group-4 {
  width: 104px;
  height: 90px;
  position: absolute;
  top: 42px;
  left: 229px;
}

.index .card-5 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  padding: 64px 24px;
  display: flex;
  position: relative;
}

.index .title-18 {
  color: var(--white);
  font-family: var(--p-1-font-family);
  font-size: var(--p-1-font-size);
  font-style: var(--p-1-font-style);
  font-weight: var(--p-1-font-weight);
  letter-spacing: var(--p-1-letter-spacing);
  line-height: var(--p-1-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.index .ant-design-smile-4 {
  width: 240px;
  height: 240px;
  position: relative;
  transform: rotate(180deg);
}

.index .overlap-group-6 {
  width: 181px;
  height: 197px;
  position: absolute;
  top: 18px;
  left: 33px;
}

.index .vector-29 {
  width: 74px;
  height: 51px;
  position: absolute;
  top: 129px;
  left: 2px;
  transform: rotate(-180deg);
}

.index .vector-30 {
  width: 78px;
  height: 65px;
  position: absolute;
  top: 2px;
  left: 75px;
  transform: rotate(-180deg);
}

.index .vector-31 {
  width: 64px;
  height: 66px;
  position: absolute;
  top: 1px;
  left: 42px;
  transform: rotate(-180deg);
}

.index .vector-32 {
  width: 106px;
  height: 65px;
  position: absolute;
  top: 130px;
  left: 76px;
  transform: rotate(-180deg);
}

.index .vector-33 {
  width: 26px;
  height: 9px;
  position: absolute;
  top: 163px;
  left: 70px;
  transform: rotate(-180deg);
}

.index .ellipse-4 {
  border: 3px solid;
  border-color: var(--white);
  border-radius: 27.19px;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 143px;
  left: 49px;
}

.index .vector-34 {
  width: 149px;
  height: 197px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.index .title-19 {
  width: 22px;
  height: 18px;
  position: absolute;
  top: 214px;
  left: 8px;
  transform: rotate(-180deg);
}

.index .title-20 {
  color: var(--yellow);
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  text-align: center;
  width: 418px;
  margin-top: -1px;
  margin-left: -19.67px;
  margin-right: -19.67px;
  position: relative;
}

.index .vector-35 {
  width: 448px;
  height: 132px;
  position: absolute;
  top: 417px;
  left: 0;
}

.index .vector-36 {
  width: 448px;
  height: 132px;
  position: absolute;
  top: 417px;
  left: 812px;
}

.index .arrow {
  width: 168px;
  height: 21px;
  position: absolute;
  top: 176px;
  left: 363px;
}

.index .arrow-2 {
  width: 168px;
  height: 43px;
  position: absolute;
  top: 166px;
  left: 729px;
}

.index .blue-blur-6 {
  background-color: var(--blue);
  filter: blur(400px);
  opacity: .24;
  border-radius: 720px;
  width: 1440px;
  height: 1440px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .stroke-circle-4 {
  border: 1px solid;
  border-color: var(--white);
  opacity: .16;
  border-radius: 401px;
  width: 802px;
  height: 802px;
  position: absolute;
  top: 638px;
  left: 319px;
}

.index .header-3 {
  justify-content: space-between;
  align-items: flex-start;
  width: 1280px;
  display: flex;
  position: absolute;
  top: 1252px;
  left: 80px;
}

.index .menu-items-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.index .menu-item-2 {
  border: 1px solid #ffffff14;
  border-radius: 80px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 24px;
  display: inline-flex;
  position: relative;
}

.index .button-3 {
  all: unset;
  background-color: var(--blue);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 60px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 40px;
  display: inline-flex;
  position: relative;
}

.index .button-3:hover {
  animation: .1s ease-out buttonPressed;
}

.index .logo-4 {
  width: 140px !important;
  height: 31px !important;
  position: absolute !important;
  top: 10px !important;
  left: 570px !important;
}

.index .footer-2 {
  background-color: #0000;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 1280px;
  display: flex;
  position: absolute;
  top: 4611px;
  left: 80px;
}

.index .header-4 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.index .menu-items-3 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-25 {
  color: #fff;
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.index .txt-container-6 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 1382px;
  left: 457px;
}

.index .vector-37 {
  width: 396px;
  height: 88px;
  position: absolute;
  top: 106px;
  left: -60px;
}

.index .vector-377 {
  width: 396px;
  height: 88px;
  position: absolute;
  top: 106px;
  left: -10px;
}

.index .title-21 {
  color: #0000;
  letter-spacing: -1.44px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 72px;
  font-weight: 700;
  line-height: 79.2px;
  position: relative;
}

.index .form-container-2 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.index .input-2 {
  color: var(--grey);
  font-family: var(--p-2-font-family);
  font-size: var(--p-2-font-size);
  font-style: var(--p-2-font-style);
  font-weight: var(--p-2-font-weight);
  letter-spacing: var(--p-2-letter-spacing);
  line-height: var(--p-2-line-height);
  background-color: #ffffff29;
  border: 1px solid #ffffff3d;
  border-radius: 80px;
  flex: 1;
  width: 344px;
  height: 50px;
  margin-top: -1px;
  padding: 16px 24px;
  position: relative;
}

.index .txt-container-7 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 2398px;
  left: 484px;
}

.index .title-22 {
  color: #0000;
  letter-spacing: -.96px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 48px;
  font-weight: 700;
  line-height: 52.8px;
  position: relative;
}

.index .txt-container-8 {
  background-color: var(--blue);
  border-radius: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 1280px;
  padding: 80px;
  display: flex;
  position: absolute;
  top: 3063px;
  left: 80px;
  overflow: hidden;
}

.index .vector-38 {
  width: 178px;
  height: 74px;
  position: absolute;
  top: 62px;
  left: 803px;
}

.index .title-23 {
  color: var(--white);
  letter-spacing: -.96px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 48px;
  font-weight: 700;
  line-height: 52.8px;
  position: relative;
}

.index .cards-4 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 64px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .vector-39 {
  width: 103px;
  height: 93px;
  position: absolute;
  top: 1523px;
  left: 230px;
}

.index .vector-40 {
  width: 128px;
  height: 76px;
  position: absolute;
  top: 2347px;
  left: 973px;
}

.index .frame-8 {
  flex-direction: column;
  align-items: center;
  gap: 64px;
  display: inline-flex;
  position: absolute;
  top: 3596px;
  left: 405px;
}

.index .txt-container-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 426px;
  display: flex;
  position: relative;
}

.index .copy-container-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 630px;
  display: flex;
  position: relative;
}

.index .content-row-6 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .text-wrapper-26 {
  color: var(--white);
  font-family: var(--p-1-font-family);
  font-size: var(--p-1-font-size);
  font-style: var(--p-1-font-style);
  font-weight: var(--p-1-font-weight);
  letter-spacing: var(--p-1-letter-spacing);
  line-height: var(--p-1-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-27 {
  color: var(--white);
  font-family: var(--p-1-font-family);
  font-size: var(--p-1-font-size);
  font-style: var(--p-1-font-style);
  font-weight: var(--p-1-font-weight);
  letter-spacing: var(--p-1-letter-spacing);
  line-height: var(--p-1-line-height);
  flex: 1;
  position: relative;
}

.index .txt-container-10 {
  background-color: #121212;
  border-radius: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 1280px;
  padding: 120px 64px;
  display: flex;
  position: absolute;
  top: 4095px;
  left: 80px;
  overflow: hidden;
}

.index .blue-blur-7 {
  background-color: var(--blue);
  filter: blur(400px);
  opacity: .24;
  border-radius: 262px;
  width: 524px;
  height: 524px;
  position: absolute;
  top: 174px;
  left: 378px;
}

.index .title-24 {
  color: var(--white);
  letter-spacing: -.96px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Epilogue, Helvetica;
  font-size: 48px;
  font-weight: 700;
  line-height: 52.8px;
  position: relative;
}

.index .tag-13 {
  background-color: var(--blue);
  border-radius: 6px;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 322px;
}

.index .tag-130 {
  background-color: var(--blue);
  border-radius: 6px;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 6px;
  display: inline-flex;
  position: absolute;
  top: -16px;
  left: 516px;
}

/*# sourceMappingURL=index.f21f1d2b.css.map */
